<template>
	<div class="scoped_cover_div">
		<div class="jobs_table sp">
			<div class>
				<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
					<section>
						<div class="form_section1">
							<table>
								<tr>
									<!-- <td>
										<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
										<div class="label_and_element_wrapper">
											<label>
												Service Name
												<span>*</span>
											</label>
											<input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.name" :maxlength="max"/>
										</div>
										</ValidationProvider>
									</td> -->
									<td>
										<ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Select Service Name
													<span>*</span>
												</label>
												<!--   <select v-model="service.provider_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Service Name</option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select> -->
												<input type="text" placeholder="Name Character Limit 30"
													:class="errors[0] ? 'warning-border' : ''" v-model="service.name" :maxlength="max" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Service Code
													<span>*</span>
												</label>
												<input type="text" placeholder="Code Character Limit 30"
													:class="errors[0] ? 'warning-border' : ''" v-model="service.code" :maxlength="max" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="weight limit" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Weight Limit <span style="font-weight: normal;color: #000;">(Please enter No Limit if there is
														no values)</span>
													<span>*</span>

												</label>

												<input type="text" placeholder="Code Character Limit 30"
													:class="errors[0] ? 'warning-border' : ''" v-model="service.weight_limit" :maxlength="max" />
												<!-- <select v-model="service.weight_limit">
												<option value="" disabled>Select Weight Limit</option>
												<option value = "1">1 KG</option>
												<option value = "5">5 KG</option> 
												<option value = "10">10 KG</option>
												<option value = "20">20 KG</option>
												<option value = "No Limit">No Limit</option>
												</select> -->
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="priority" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Priority
													<span>*</span>
												</label>
												<select v-model="service.priority">
													<option value="Standard">Standard</option>
													<option value="Express">Express</option>
													<option value="Immediate">Immediate</option>
												</select>
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Type of Goods
											</label>
											<div>
												<label class="label-radio chk_label">
													<span>Document</span>
													<input type="checkbox" class="chk_prefered" name="type_of_goods_document" v-model="service.type_of_goods_document" true-value="1" false-value="0">
												</label>
												<label class="label-radio chk_label">
													<span>Parcel</span>
													<input type="checkbox" class="chk_prefered" name="type_of_goods_parcel" v-model="service.type_of_goods_parcel" true-value="1" false-value="0">
												</label>
											</div>
										</div>
									</td>
								</tr>

							</table>
						</div>
					</section>
				</ValidationObserver>

				<section class="centered">
					<a class="link_bt bt_save" @click="submit">Save</a>
				</section>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "CarrierServices",
	data() {
		return {
			max: 30,

			service: {
				name: "",
				code: "",
				weight_limit: "",
				priority: "Standard",
				type_of_goods_document: 0,
				type_of_goods_parcel: 0,
			}
		}
	},
	created() {
		//this.fetchCarriers();
	},
	methods: {

		submit() {
			this.$refs.observer.validate();
			this.axios.post("/api/service/", this.service)
				.then(response => {
					this.toast.success(response.data.msg);
					this.$emit('redirect');
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
};
</script>
<style  scoped >
.scoped_cover_div {
	margin: 0;
}

.scoped_cover_div section {
	padding: 0;
}

.scoped_cover_div .form_section1 {
	margin-top: 0;
}

.jobs_table.sp table {
	width: 100%;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
	border: 0;
	padding: 0 0 20px 0;
	vertical-align: top;
}

.jobs_table.sp table tr td {
	width: 33%;
}

.user_profile_upload {
	display: block;
	border: 0;
	height: 40px;
	width: 200px;
	background: #004a7c;
	color: #fff;
	font-size: 18px;
	margin-right: 10px;
	position: relative;
}

.user_profile_upload input {
	opacity: 0;
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.user_profile_upload span {
	display: block;
	width: 100%;
	height: 40px;
	text-align: center;
	line-height: 30px;
	text-align: center;
	color: #000;
	font-weight: normal;
}

.label-radio {
	display: inline-block;
}

.label-radio span {
	color: #333;
}

.chk_label {
  display: inline-block;
  margin: 10px 20px 10px 10px;
  background: #fff;
  height: 50px;
  border: 1px solid #ccc;
}
.chk_label span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  top: 5px;
}
.chk_label input {
  display: inline-block;
  position: relative;
  top: 10px;
}
</style>